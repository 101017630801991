<template>
  <div class="page">
    <Navbar title="生活习惯" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-field
        v-model="habit.smokeName"
        readonly
        clickable
        name="吸烟习惯"
        label="吸烟习惯"
        placeholder="点击选择吸烟习惯"
        @click="habit.smokeShow = true"
      />
      <van-popup :show="habit.smokeShow" position="bottom">
        <van-picker
          :columns="smokeList"
          :columns-field-names="{
            text: 'name',
          }"
          @confirm="confirmSmoke"
          @cancel="habit.smokeShow = false"
        />
      </van-popup>
      <van-field
        v-model="habit.drinkName"
        readonly
        clickable
        name="喝酒习惯"
        label="喝酒习惯"
        placeholder="点击选择喝酒习惯"
        @click="habit.drinkShow = true"
      />
      <van-popup :show="habit.drinkShow" position="bottom">
        <van-picker
          :columns="drinkList"
          :columns-field-names="{
            text: 'name',
          }"
          @confirm="confirmDrink"
          @cancel="habit.drinkShow = false"
        />
      </van-popup>
      <van-field
        v-model="habit.sportName"
        readonly
        clickable
        name="运动习惯"
        label="运动习惯"
        placeholder="点击选择运动习惯"
        @click="habit.sportShow = true"
      />
      <van-popup :show="habit.sportShow" position="bottom">
        <van-picker
          :columns="sportList"
          :columns-field-names="{
            text: 'name',
          }"
          @confirm="confirmSport"
          @cancel="habit.sportShow = false"
        />
      </van-popup>
      <van-field
        v-model="habit.travelName"
        readonly
        clickable
        name="旅行习惯"
        label="旅行习惯"
        placeholder="点击选择喝酒习惯"
        @click="habit.travelShow = true"
      />
      <van-popup :show="habit.travelShow" position="bottom">
        <van-picker
          :columns="travelList"
          :columns-field-names="{
            text: 'name',
          }"
          @confirm="confirmTravel"
          @cancel="habit.travelShow = false"
        />
      </van-popup>
      <van-field
        v-model="habit.partyName"
        readonly
        clickable
        name="聚会习惯"
        label="聚会习惯"
        placeholder="点击选择聚会习惯"
        @click="habit.partyShow = true"
      />
      <van-popup :show="habit.partyShow" position="bottom">
        <van-picker
          :columns="partyList"
          :columns-field-names="{
            text: 'name',
          }"
          @confirm="confirmParty"
          @cancel="habit.partyShow = false"
        />
      </van-popup>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Form, Field, CellGroup, Picker, Popup } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  data () {
    return {
      step: 2,
      personCode: '',
      loadingShow: false,
      smokeList: [],
      drinkList: [],
      travelList: [],
      partyList: [],
      sportList: [],
      habit: {
        smokeCode: '',
        smokeName: '',
        smokeShow: false,
        drinkCode: '',
        drinkName: '',
        drinkShow: false,
        travelCode: '',
        travelName: '',
        travelShow: false,
        partyCode: '',
        partyName: '',
        partyShow: false,
        sportCode: '',
        sportName: '',
        sportShow: false
      }
    }
  },
  mounted () {
    var query = this.$route.query
    var code = query.personCode
    if (code !== '' && code !== undefined && code !== null) {
      this.personCode = code
      this.retrieveAttr()
    }
    this.$refs.share.default('main')
    this.retrieveSmokeOption()
    this.retrieveDrinkOption()
    this.retrieveTravelOption()
    this.retrievePartyOption()
    this.retrieveSportOption()
  },
  methods: {
    confirmSmoke (value) {
      this.habit.smokeCode = value.code
      this.habit.smokeName = value.name
      this.habit.smokeShow = false
    },
    confirmDrink (value) {
      this.habit.drinkCode = value.code
      this.habit.drinkName = value.name
      this.habit.drinkShow = false
    },
    confirmTravel (value) {
      this.habit.travelCode = value.code
      this.habit.travelName = value.name
      this.habit.travelShow = false
    },
    confirmParty (value) {
      this.habit.partyCode = value.code
      this.habit.partyName = value.name
      this.habit.partyShow = false
    },
    confirmSport (value) {
      this.habit.sportCode = value.code
      this.habit.sportName = value.name
      this.habit.sportShow = false
    },
    async submit () {
      this.loadingShow = true
      var attrs = [
        { key: 'PSN_HBT_SMOKE', type: 'DTL', content: { code: this.habit.smokeCode, name: this.habit.smokeName }, seq: '1' },
        { key: 'PSN_HBT_DRINK', type: 'DTL', content: { code: this.habit.drinkCode, name: this.habit.drinkName }, seq: '1' },
        { key: 'PSN_HBT_TRAVEL', type: 'DTL', content: { code: this.habit.travelCode, name: this.habit.travelName }, seq: '1' },
        { key: 'PSN_HBT_PARTY', type: 'DTL', content: { code: this.habit.partyCode, name: this.habit.partyName }, seq: '1' },
        { key: 'PSN_HBT_SPORT', type: 'DTL', content: { code: this.habit.sportCode, name: this.habit.sportName }, seq: '1' }
      ]
      var pd = {
        personCode: this.personCode,
        attrs: JSON.stringify(attrs)
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '生活习惯',
          message: '保存成功'
        }).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$dialog.alert({
          title: '生活习惯',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrieveAttr () {
      var pd = { personCode: this.personCode, group: 'HBT', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_HBT_SMOKE') {
            this.habit.smokeCode = obj.code
            this.habit.smokeName = obj.name
          } else if (element.key === 'PSN_HBT_DRINK') {
            this.habit.drinkCode = obj.code
            this.habit.drinkName = obj.name
          } else if (element.key === 'PSN_HBT_TRAVEL') {
            this.habit.travelCode = obj.code
            this.habit.travelName = obj.name
          } else if (element.key === 'PSN_HBT_PARTY') {
            this.habit.partyCode = obj.code
            this.habit.partyName = obj.name
          } else if (element.key === 'PSN_HBT_SPORT') {
            this.habit.sportCode = obj.code
            this.habit.sportName = obj.name
          }
        })
      }
    },
    async retrieveSmokeOption () {
      var pd = { key: 'HABIT_SMOKE' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.smokeList = res.data.item
      }
    },
    async retrieveDrinkOption () {
      var pd = { key: 'HABIT_DRINK' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.drinkList = res.data.item
      }
    },
    async retrieveTravelOption () {
      var pd = { key: 'HABIT_TRAVEL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.travelList = res.data.item
      }
    },
    async retrievePartyOption () {
      var pd = { key: 'HABIT_PARTY' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.partyList = res.data.item
      }
    },
    async retrieveSportOption () {
      var pd = { key: 'HABIT_SPORT' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/data/option/retrieveOptions', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.sportList = res.data.item
      }
    }
  }
}
</script>
<style lang="less" scoped>
.form {
  padding: 0px 5px;
  margin-bottom: 30px;
}
.title {
  font-size: 13px;
  padding: 8px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
